import React from "react";
import Layout from "../components/layout";

const Kozossegek = () => {
    return (
        <Layout>
            <h1>Közösségek</h1>
        </Layout>
    )
}

export default Kozossegek;